.uconfy-auth-card {
  @include media-breakpoint-down('xs') {
    min-width: 90%;
    max-width: 90%;
  }
  @include media-breakpoint-up('sm') {
    min-width: 500px;
    max-width: 500px;
  }
  @include media-breakpoint-up('md') {
    min-width: 700px;
    max-width: 700px;
  }
}


.green-border-left {
  border-left: #75FF33 solid 4px !important;
}

.red-border-left {
  border-left: #FF5733  solid 4px !important;
}

.grey-border-left {
  border-left:  #8f938d solid 4px !important;
}

.violet-border-left {
  border-left: #b784f4 solid 4px !important;
}
