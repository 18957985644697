// User styles
//
// This is the place where you should place all additional styles and imports.
// This will allow you to take advantage of future updates with lower time consumed on the upgrade process.
@import 'custom/uconfy';

.select2-container--classic .select2-selection--single .select2-selection__placeholder,
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: $input-placeholder-color;
}


[aria-expanded="true"] .icon-collapse-hidden,
[aria-expanded="false"] .icon-collapse-shown {
    display:none;
}


@each $color, $value in $grays  {
  .text-gray-#{$color} {
    color: $value;
  }
}

.uconfy-file-input.dropdown-item {
  cursor: pointer;
}

//[data-plugin="uconfy-select-paper-reviewer"] .select2-selection.select2-selection--single {
//  padding-top: 2px;
//  padding-bottom: 2px;
//}

.green-border-left {
  border-left: #75FF33 solid 4px !important;
}

.red-border-left {
  border-left: #FF5733  solid 4px !important;
}

.grey-border-left {
  border-left:  #8f938d solid 4px !important;
}

.violet-border-left {
  border-left: #b784f4 solid 4px !important;
}
